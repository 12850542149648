import React from "react";

const Section3 = ({ url }) => {
  return (
    <>
      <section className="about-section-3">
        <div
          className="container about-us"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <h5>
            <strong style={{ color: "#fcb61d" }}>Let's Work Together - </strong>
            Whether you're a startup looking to establish your digital presence
            or an established brand seeking a fresh perspective, ExD Digital is
            here to help you succeed. Let's collaborate and embark on a digital
            journey that will elevate your brand to new heights. Join us in
            shaping your future in the digital world!
          </h5>
        </div>
      </section>
    </>
  );
};

export default Section3;
