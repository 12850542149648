import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const Navbar = () => {
  const [isLogoVisible, setLogoVisible] = useState(true);
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    const handle_Scroll = () => {
      if (window.scrollY > 100) {
        setLogoVisible(false);
      } else {
        setLogoVisible(true);
      }
    };

    window.addEventListener("scroll", handle_Scroll);

    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleMenuItemClick = () => {
    setIsExpanded(false);
  };

  return (
    <nav className="main-nav">
      <div className="container-fluid navbar-container">
        <div className="main-nav_inner">
          <Link
            to="/"
            className={`navbar-logo ${isLogoVisible ? "visible" : ""}`}
          >
            <img loading="lazy"
              src="https://www.exddigital.com/images/exd_digital.png"
              alt="ExD Digital"
              className="navbar-logo-img"
              style={{ height: "auto" }}
            />
          </Link>
          <div
            className={`toggle ${isExpanded ? "active" : ""}`}
            onClick={toggleExpand}
          >
            <span className="toggle-btn-line"></span>
            <span className="toggle-btn-line"></span>
            <span className="toggle-btn-line"></span>
          </div>
          <div className={`main-nav_expandable ${isExpanded ? "js-open" : ""}`}>
            <div className="main-nav_expandable-inner">
              <div className="container">
                <div className="main-nav_expandable-content">
                  <button
                    className="main-nav_toggler toggler-close"
                    type="button"
                    onClick={toggleExpand}
                  ></button>
                  <ul className="main-nav_list">
                    <li className="main-nav_item">
                      <Link
                        to="/"
                        className="main-nav_link"
                        onClick={handleMenuItemClick}
                      >
                        <span className="main-nav_link-title">Home</span>
                      </Link>
                    </li>
                    <li className="main-nav_item">
                      <Link
                        to="/about-us"
                        className="main-nav_link"
                        onClick={handleMenuItemClick}
                      >
                        <span className="main-nav_link-title">About Us</span>
                      </Link>
                    </li>
                    <li className="main-nav_item">
                      <Link
                        to="/our-work"
                        className="main-nav_link"
                        onClick={handleMenuItemClick}
                      >
                        <span className="main-nav_link-title">Our Work</span>
                      </Link>
                    </li>
                    <li className="main-nav_item">
                      <Link
                        to="/services"
                        className="main-nav_link"
                        onClick={handleMenuItemClick}
                      >
                        <span className="main-nav_link-title">Services</span>
                      </Link>
                    </li>
                    <li className="main-nav_item">
                      <Link
                        to="/meet-the-team"
                        className="main-nav_link"
                        onClick={handleMenuItemClick}
                      >
                        <span className="main-nav_link-title">
                          Meet the Team
                        </span>
                      </Link>
                    </li>
                    <li className="main-nav_item">
                      <Link
                        to="/contact-us"
                        className="main-nav_link"
                        onClick={handleMenuItemClick}
                      >
                        <span className="main-nav_link-title">Contact Us</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
