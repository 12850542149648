import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const Section5 = ({ url }) => {
  // to apply animation on bigger screen only
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // to shift between container and container-fluid for different screen sizes

  const getContainerClass = () => {
    if (screenWidth <= 991) {
      return "container"; // Use 'container' class for screens smaller than 992
    }

    if (screenWidth > 1440) {
      return "container-fluid"; // Use 'container' class for  screens bigger than 1440
    }

    return "container-fluid"; // Use 'container-fluid' class for screens between 991 & 1441
  };

  const col_1_in = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image1) {
        image1.style.width = "100%";
        image1.style.transform = "scale(1.6)";
        image1.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image2) {
        image2.style.width = "0%";
      }
      if (image3) {
        image3.style.width = "0%";
      }
      if (image4) {
        image4.style.width = "0%";
      }
    }
  };

  const col_1_out = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image1) {
        image1.style.width = "25%";
        image1.style.transform = "scale(1)";
        image1.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image2) {
        image2.style.width = "25%";
      }
      if (image3) {
        image3.style.width = "25%";
      }
      if (image4) {
        image4.style.width = "25%";
      }
    }
  };

  const col_2_in = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image2) {
        image2.style.width = "100%";
        image2.style.transform = "scale(1.6)";
        image2.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image1) {
        image1.style.width = "0%";
      }
      if (image3) {
        image3.style.width = "0%";
      }
      if (image4) {
        image4.style.width = "0%";
      }
    }
  };

  const col_2_out = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image2) {
        image2.style.width = "25%";
        image2.style.transform = "scale(1)";
        image2.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image1) {
        image1.style.width = "25%";
      }
      if (image3) {
        image3.style.width = "25%";
      }
      if (image4) {
        image4.style.width = "25%";
      }
    }
  };

  const col_3_in = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image3) {
        image3.style.width = "100%";
        image3.style.transform = "scale(1.6)";
        image3.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image2) {
        image2.style.width = "0%";
      }
      if (image1) {
        image1.style.width = "0%";
      }
      if (image4) {
        image4.style.width = "0%";
      }
    }
  };

  const col_3_out = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image3) {
        image3.style.width = "25%";
        image3.style.transform = "scale(1)";
        image3.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image2) {
        image2.style.width = "25%";
      }
      if (image1) {
        image1.style.width = "25%";
      }
      if (image4) {
        image4.style.width = "25%";
      }
    }
  };

  const col_4_in = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image4) {
        image4.style.width = "100%";
        image4.style.transform = "scale(1.6)";
        image4.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image2) {
        image2.style.width = "0%";
      }
      if (image3) {
        image3.style.width = "0%";
      }
      if (image1) {
        image1.style.width = "0%";
      }
    }
  };

  const col_4_out = () => {
    if (screenWidth > 991) {
      const image1 = document.querySelector(".image1");
      const image2 = document.querySelector(".image2");
      const image3 = document.querySelector(".image3");
      const image4 = document.querySelector(".image4");
      if (image4) {
        image4.style.width = "25%";
        image4.style.transform = "scale(1)";
        image4.style.transition = "transform .4s, width 0.6s ease-in-out";
      }
      if (image2) {
        image2.style.width = "25%";
      }
      if (image3) {
        image3.style.width = "25%";
      }
      if (image1) {
        image1.style.width = "25%";
      }
    }
  };

  return (
    <section className="home-section-5">
      <div className={`main-div ${getContainerClass()}`}>
        <div className="row" id="main-div">
          <div className="parentBox">
            <div
              className="image1"
              style={{
                backgroundImage:
                  "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(" +
                  url +
                  "images/homepage/app-web-dev.jpg)",
              }}
            ></div>
            <div
              className="image2"
              style={{
                backgroundImage:
                  "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(" +
                  url +
                  "images/homepage/ai.jpg)",
              }}
            ></div>
            <div
              className="image3"
              style={{
                backgroundImage:
                  "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(" +
                  url +
                  "images/homepage/smm.jpg)",
              }}
            ></div>
            <div
              className="image4"
              style={{
                backgroundImage:
                  "linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),url(" +
                  url +
                  "images/homepage/branding.jpg)",
              }}
            ></div>
          </div>
          <div className="column col-lg-3 col-md-6 col-12 p-0 m-0" id="col-1">
            <div
              className="column1"
              onMouseOver={col_1_in}
              onMouseOut={col_1_out}
            >
              <div className="cover"></div>
              <div className="description">
                <span>
                  <h2>01</h2>
                </span>
                <p>Software Development</p>
                <p className="field-desc">
                  Whether you need a web portal, a mobile app, or a stunning
                  website, our team of seasoned developers, designers, and UX/UI
                  experts work collaboratively to bring your vision to life.
                </p>
                <Link to="/services" className="descp-link">
                  <FontAwesomeIcon icon={faArrowRightLong} className="i" />
                </Link>
              </div>
            </div>
          </div>
          <div className="column col-lg-3 col-md-6 col-12 p-0 m-0" id="col-2">
            <div
              className="column2"
              id="col-2"
              onMouseOver={col_2_in}
              onMouseOut={col_2_out}
            >
              <div className="cover"></div>
              <div className="description">
                <span>
                  <h2>02</h2>
                </span>
                <p>AI Projects</p>
                <p className="field-desc">
                  Our team of AI specialists harness the power of machine and
                  deep learning to develop intelligent systems enhancing user
                  experiences, streamlining processes, and driving business
                  growth making your digital presence innovatively efficient.
                  {/* Grab your potential customer’s attention with creative &
                  consistent branding across all platforms to stand out in the
                  crowd. So, <span className="bold">Be Bold</span>,{" "}
                  <span className="light">Be Light</span>,{" "}
                  <span className="italic">Be Italic</span>, but never be{" "}
                  <span className="regular">Regular</span> */}
                </p>
                <Link to="/services" className="descp-link">
                  <FontAwesomeIcon icon={faArrowRightLong} className="i" />
                </Link>
              </div>
            </div>
          </div>
          <div className="column col-lg-3 col-md-6 col-12 p-0 m-0" id="col-3">
            <div
              className="column3"
              id="col-lg-3 col-md-6 col-12"
              onMouseOver={col_3_in}
              onMouseOut={col_3_out}
            >
              <div className="cover"></div>
              <div className="description">
                <span>
                  <h2>03</h2>
                </span>
                <p>Digital Marketing</p>
                <p className="field-desc">
                  Our social media mavens craft data-driven campaigns tailored
                  to suit unique audiences, delivering engaging content with
                  measurable results. We go beyond enhancing online visibility
                  to generate high-quality leads that transform into loyal
                  customers.
                </p>
                <Link to="/services" className="descp-link">
                  <FontAwesomeIcon icon={faArrowRightLong} className="i" />
                </Link>
              </div>
            </div>
          </div>
          <div className="column col-lg-3 col-md-6 col-12 p-0 m-0" id="col-4">
            <div
              className="column4"
              id="col-4"
              onMouseOver={col_4_in}
              onMouseOut={col_4_out}
            >
              <div className="cover"></div>
              <div className="description">
                <span>
                  <h2>04</h2>
                </span>
                <p>Branding</p>
                <p className="field-desc">
                  From creating captivating brand identities to comprehensive
                  branding strategies, we enhance the compellingly consistent
                  and memorable features of your brand story to transforming it
                  into a magnetic force in the digital world.
                </p>
                <Link to="/services" className="descp-link">
                  <FontAwesomeIcon icon={faArrowRightLong} className="i" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-div-2">
        <div className="container container-section-5-2">
          <div className="row section-5-2-row">
            <div className="col-12 services-section">
              <div className="row services-section-row">
                <div className="col-lg-3 col-md-3 col-12 services-content-img">
                  <div className="left-img">
                    <img
                      loading="lazy"
                      src={url + "images/homepage/app-web-dev.jpg"}
                      alt="App & Web Development"
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-12 services-content">
                  <div className="description">
                    <span>
                      <h2>01</h2>
                    </span>
                    <p className="desc-title">Software Development</p>
                    <p className="field-desc">
                      Whether you need a web portal, a mobile app, or a stunning
                      website, our team of seasoned developers, designers, and
                      UX/UI experts work collaboratively to bring your vision to
                      life.
                    </p>
                    <Link to="/services" className="desc-link">
                      <p className="desc-link-title">
                        View
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="i"
                        />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 services-section">
              <div className="row services-section-row">
                <div className="col-lg-3 col-md-3 col-12 services-content-img">
                  <div className="left-img">
                    <img
                      loading="lazy"
                      src={url + "images/homepage/ai.jpg"}
                      alt="AI Powered Projects"
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-12 services-content">
                  <div className="description">
                    <span>
                      <h2>02</h2>
                    </span>
                    <p className="desc-title">AI Projects</p>
                    <p className="field-desc">
                      Our team of AI specialists harness the power of machine
                      and deep learning to develop intelligent systems enhancing
                      user experiences, streamlining processes, and driving
                      business growth making your digital presence innovatively
                      efficient.
                      {/* Grab your potential customer’s attention with creative &
                      consistent branding across all platforms to stand out in
                      the crowd. So, <span className="bold">Be Bold</span>,{" "}
                      <span className="light">Be Light</span>,{" "}
                      <span className="italic">Be Italic</span>, but never be{" "}
                      <span className="regular">Regular</span> */}
                    </p>
                    <Link to="/services" className="desc-link">
                      <p className="desc-link-title">
                        View
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="i"
                        />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 services-section">
              <div className="row services-section-row">
                <div className="col-lg-3 col-md-3 col-12 services-content-img">
                  <div className="left-img">
                    <img
                      loading="lazy"
                      src={url + "images/homepage/smm.jpg"}
                      alt="Digital Marketing"
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-12 services-content">
                  <div className="description">
                    <span>
                      <h2>03</h2>
                    </span>
                    <p className="desc-title">Digital Marketing</p>
                    <p className="field-desc">
                      Our team of AI specialists harness the power of machine
                      and deep learning to develop intelligent systems enhancing
                      user experiences, streamlining processes, and driving
                      business growth making your digital presence innovatively
                      efficient.
                    </p>
                    <Link to="/services" className="desc-link">
                      <p className="desc-link-title">
                        View
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="i"
                        />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 services-section">
              <div className="row services-section-row">
                <div className="col-lg-3 col-md-3 col-12 services-content-img">
                  <div className="left-img">
                    <img
                      loading="lazy"
                      src={url + "images/homepage/branding.jpg"}
                      alt="Branding"
                    />
                  </div>
                </div>
                <div className="col-lg-9 col-md-9 col-12 services-content">
                  <div className="description">
                    <span>
                      <h2>04</h2>
                    </span>
                    <p className="desc-title">Branding</p>
                    <p className="field-desc">
                      From creating captivating brand identities to
                      comprehensive branding strategies, we enhance the
                      compellingly consistent and memorable features of your
                      brand story to transforming it into a magnetic force in
                      the digital world.
                    </p>
                    <Link to="/services" className="desc-link">
                      <p className="desc-link-title">
                        View
                        <FontAwesomeIcon
                          icon={faArrowRightLong}
                          className="i"
                        />
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-div-3">
        <div className="container-section-5-3">
          <div className="section-5-3-row">
            <div className="col-12 services-section">
              <div className="services-section-row">
                <div className="services-content">
                  <div className="description">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(" +
                          url +
                          "images/homepage/app-web-dev.jpg)",
                      }}
                    >
                      <span>
                        <h2>01</h2>
                      </span>
                      <p className="desc-title">Software Development</p>
                      <p className="field-desc">
                        Whether you need a web portal, a mobile app, or a
                        stunning website, our team of seasoned developers,
                        designers, and UX/UI experts work collaboratively to
                        bring your vision to life.
                      </p>
                      <Link to="/services" className="desc-link">
                        <p className="desc-link-title">
                          View
                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="i"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 services-section">
              <div className="services-section-row">
                <div className="services-content">
                  <div className="description">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(" +
                          url +
                          "images/homepage/ai.jpg)",
                      }}
                    >
                      <span>
                        <h2>02</h2>
                      </span>
                      <p className="desc-title">AI Projects</p>
                      <p className="field-desc">
                        Our team of AI specialists harness the power of machine
                        and deep learning to develop intelligent systems
                        enhancing user experiences, streamlining processes, and
                        driving business growth making your digital presence
                        innovatively efficient.
                        {/* Grab your potential customer’s attention with creative &
                        consistent branding across all platforms to stand out in
                        the crowd. So, <span className="bold">Be Bold</span>,{" "}
                        <span className="light">Be Light</span>,{" "}
                        <span className="italic">Be Italic</span>, but never be{" "}
                        <span className="regular">Regular</span> */}
                      </p>
                      <Link to="/services" className="desc-link">
                        <p className="desc-link-title">
                          View
                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="i"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 services-section">
              <div className="services-section-row">
                <div className="services-content">
                  <div className="description">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(" +
                          url +
                          "images/homepage/smm.jpg)",
                      }}
                    >
                      <span>
                        <h2>03</h2>
                      </span>
                      <p className="desc-title">Digital Marekting</p>
                      <p className="field-desc">
                        Our social media mavens craft data-driven campaigns
                        tailored to suit unique audiences, delivering engaging
                        content with measurable results. We go beyond enhancing
                        online visibility to generate high-quality leads that
                        transform into loyal customers.
                      </p>
                      <Link to="/services" className="desc-link">
                        <p className="desc-link-title">
                          View
                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="i"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 services-section">
              <div className="services-section-row">
                <div className="services-content">
                  <div className="description">
                    <div
                      className="bg-img"
                      style={{
                        backgroundImage:
                          "linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),url(" +
                          url +
                          "images/homepage/branding.jpg)",
                      }}
                    >
                      <span>
                        <h2>04</h2>
                      </span>
                      <p className="desc-title">Branding</p>
                      <p className="field-desc">
                        From creating captivating brand identities to
                        comprehensive branding strategies, we enhance the
                        compellingly consistent and memorable features of your
                        brand story to transforming it into a magnetic force in
                        the digital world.
                      </p>
                      <Link to="/services" className="desc-link">
                        <p className="desc-link-title">
                          View
                          <FontAwesomeIcon
                            icon={faArrowRightLong}
                            className="i"
                          />
                        </p>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section5;
