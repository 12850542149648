import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Section1 = ({ url }) => {
  useEffect(() => {
    const spotlightSize = 400;

    function handleMouseMove(event) {
      if (window.event) {
        event = window.event;
      }
      let titleRect = document
        .querySelector(".hollow-txt")
        .getBoundingClientRect();
      let mouseX = event.clientX;
      let spotlightX = mouseX - spotlightSize / 2 - titleRect.left;
      let mouseY = event.clientY;
      let spotlightY = mouseY - spotlightSize / 2 - titleRect.top;
      const element = document.querySelector(".hollow-txt");
      element.style.backgroundPosition = spotlightX + "px " + spotlightY + "px";
    }

    document.addEventListener("mousemove", handleMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <section className="contact-section-1" style={{
      backgroundImage: "url(" + url + "images/contact-us/main-banner.jpg)",
    }}>
        <div className="container sect-1-container">
          <div
            className="txt-1 col-12"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h1 className="hollow-txt">
              <span>
                Contact <span className="white-txt">Us</span>
              </span>
            </h1>
          </div>
          <div
            className="services-desc"
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <h6 className="desc">
              From start to scaling, ExD Digital is there to help your brand at
              any stage, whether it be launch or growth, we've got you covered.
              so contact us <span>NOW!</span>
            </h6>
          </div>
          <div className="scroller">
            <p className="scroller-text">Scroll Down</p>
            <a href="#contact-section-2" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faArrowDown} className="i" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
