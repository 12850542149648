import React, { useEffect, useState } from "react";

const ProgressBar = () => {
  useEffect(() => {
    const progressBar = document.querySelector(".progressBar");
    const section = document.querySelector("section");

    const scrollProgressBar = () => {
      let scrollDistance = -section.getBoundingClientRect().top;
      let progressPercentage =
        (scrollDistance /
          (section.getBoundingClientRect().height -
            document.documentElement.clientHeight)) *
        100;

      let val = Math.floor(progressPercentage);
      progressBar.style.width = val + "%";

      if (val < 0) {
        progressBar.style.width = "0%";
      }
    };

    window.addEventListener("scroll", scrollProgressBar);

    return () => {
      window.removeEventListener("scroll", scrollProgressBar);
    };
  }, []);

  const [scrollProgress, setScrollProgress] = useState(0);

  useEffect(() => {
    const calculateScrollProgress = () => {
      const scrollY = window.scrollY || window.pageYOffset;
      const pageHeight =
        document.documentElement.scrollHeight -
        document.documentElement.clientHeight;
      const progress = (scrollY / pageHeight) * 100;
      setScrollProgress(progress);
    };

    const handle_Scroll = () => {
      requestAnimationFrame(calculateScrollProgress);
    };

    window.addEventListener("scroll", handle_Scroll);

    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  return (
    <section>
      <div className="progressBar"></div>
      <div
        className="scroll-progress-bar"
        style={{ width: `${scrollProgress}%` }}
      />
    </section>
  );
};

export default ProgressBar;
