import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section3 = ({ url }) => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],

    centerMode: true, // Add this property
    centerPadding: "0px", // Adjust the value as per your requirement
  };
  return (
    <>
      <section className="our-work-section-3" id="our-work-section-3">
        <Slider {...settings} >
          <div className="container-center">
            <div
              className="container-fluid sect-3-container"
              data-aos="fade-in"
              data-aos-duration="1200"
            >
              <div className="row sect-3-row">
                <div className="col-lg-4 col-md-4 col-12 v-col col-even">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/1.jpg"}
                      alt="Cosmetic Skin"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>Cosmetic Skin</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/1.png"}
                      alt="ExD x Gitex"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>ExD x Gitex</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-even">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/2.jpg"}
                      alt="FFL-MCAS"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>FFL-MCAS</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/2.png"}
                      alt="LUMS-SDSB"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>LUMS-SDSB</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-even col-5">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/3.jpg"}
                      alt="sabzease"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>sabzease</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/3.png"}
                      alt="uTuur.com"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>uTuur.com</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-center">
            <div className="container-fluid sect-3-container">
              <div className="row sect-3-row">
                <div className="col-lg-4 col-md-4 col-12 v-col col-even">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/4.jpg"}
                      alt="ExD"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>ExD</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/4.jpg"}
                      alt="Brain Box - Hive"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>Brain Box - Hive</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-even">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/5.jpg"}
                      alt="AFAQ"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>AFAQ</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/5.png"}
                      alt="ExD"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>ExD</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-even col-5">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/6.jpg"}
                      alt="Cosmetic Skin"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>Cosmetic Skin</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/6.png"}
                      alt="AURRAH"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>AURRAH</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-center">
            <div className="container-fluid sect-3-container">
              <div className="row sect-3-row">
                <div className="col-lg-4 col-md-4 col-12 v-col col-even">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/7.jpg"}
                      alt="Brain Box"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>Brain Box</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/7.png"}
                      alt="shahalami.pk"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>shahalami.pk</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-even">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/8.jpg"}
                      alt="PSO"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>PSO</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/8.jpg"}
                      alt="QRI"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>QRI</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-even col-5">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/long_images/9.jpg"}
                      alt="Firenze"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>Firenze</h4>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-12 v-col col-odd">
                  <div className="wrapper">
                    <img
                      loading="lazy"
                      src={url + "images/our-work/Portfolio/short_images/9.jpg"}
                      alt="shahalami.pk"
                    />
                    <div className="overlay">
                      <div className="overlay-txt">
                        <h4>shahalami.pk</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </section>
      <section className="our-work-section-2" id="our-work-section-2">
        <div
          className="our-work-container-fluid"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="portfolio-row">
            <Slider {...settings} className="sliderContainer">
              <div className="portfolio portfolio-1">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/1.jpg"}
                  alt="ExD"
                />
              </div>
              <div className="portfolio portfolio-2">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/2.jpg"}
                  alt="ExD x Gitex"
                />
              </div>
              <div className="portfolio portfolio-3">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/3.jpg"}
                  alt="LUMS - SDSB"
                />
              </div>
              <div className="portfolio portfolio-4">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/4.jpg"}
                  alt="uTuur.com"
                />
              </div>
              <div className="portfolio portfolio-5">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/5.jpg"}
                  alt="shahalami.pk"
                />
              </div>
              <div className="portfolio portfolio-6">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/6.jpg"}
                  alt="ExD"
                />
              </div>
              <div className="portfolio portfolio-7">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/7.jpg"}
                  alt="AURRAH"
                />
              </div>
              <div className="portfolio portfolio-8">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/8.jpg"}
                  alt="Brain Box - Hive"
                />
              </div>
              <div className="portfolio portfolio-9">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/9.jpg"}
                  alt="Qarshi"
                />
              </div>
              <div className="portfolio portfolio-10">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/10.jpg"}
                  alt="shahalami.pk"
                />
              </div>
              <div className="portfolio portfolio-11">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/11.jpg"}
                  alt="Firenze"
                />
              </div>
              <div className="portfolio portfolio-12">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/12.jpg"}
                  alt="PSO"
                />
              </div>
              <div className="portfolio portfolio-13">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/13.jpg"}
                  alt="Brain Box"
                />
              </div>
              <div className="portfolio portfolio-14">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/14.jpg"}
                  alt="Cosmetic Skin"
                />
              </div>
              <div className="portfolio portfolio-15">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/15.jpg"}
                  alt="Cosmetic Skin"
                />
              </div>
              <div className="portfolio portfolio-16">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/16.jpg"}
                  alt="AFAQ"
                />
              </div>
              <div className="portfolio portfolio-17">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/17.jpg"}
                  alt="ExD"
                />
              </div>
              <div className="portfolio portfolio-18">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/18.jpg"}
                  alt="sabzease"
                />
              </div>
              <div className="portfolio portfolio-19">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/19.jpg"}
                  alt="FFL - MCAS"
                />
              </div>
              <div className="portfolio portfolio-20">
                <img
                  loading="lazy"
                  src={url + "images/our-work/Portfolio/mobile_images/20.jpg"}
                  alt="Firenze"
                />
              </div>
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
