import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section8 = ({ url }) => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,

    centerMode: true, // Add this property
    centerPadding: "0px", // Adjust the value as per your requirement
  };

  return (
    <section
      className="home-section-8"
      style={{
        backgroundImage: "url(" + url + "images/homepage/testimonials-bg.jpg)",
      }}
    >
      <div
        className="container sect-8-container"
        data-aos="fade-down"
        data-aos-duration="1000"
      >
        <h1 className="header">Reviews & Comments</h1>
        <Slider {...settings} className="client-testimonials">
          <div className="testimonial">
            <h3 className="title">
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" /> Rungrez{" "}
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
            </h3>
            <div className="content">
              <h6>
                "I can say that our brand Rungrez would have been incomplete if
                we did not have as great a partner as ExD Digital to handle our
                digital platform. By managing our social media, they have spread
                the philosophy behind the brand and the collection to the right
                audience and in process have ensured maximum conversion"
              </h6>
            </div>
            <div className="owner">
              <h5>-- Hur Hassan -Owner --</h5>
            </div>
          </div>
          <div className="testimonial">
            <h3 className="title">
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" /> Rani Eman{" "}
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
            </h3>
            <div className="content">
              <h6>
                "ExD has an extremely creative workforce, with beautiful
                designing capabilities and innovative ways to make my brand more
                welcoming to the buyers, especially the ones who visited my
                social media pages! They’ve always professionally dealt with the
                issues I had and have helped me make loyal customers!"
              </h6>
            </div>
            <div className="owner">
              <h5>-- Farzeen Irtiza -Owner --</h5>
            </div>
          </div>
          <div className="testimonial">
            <h3 className="title">
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" /> Ravish{" "}
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
            </h3>
            <div className="content">
              <h6>
                "Ravish has grown exponentially thanks to ExD’s support in
                developing by brand identify and managing my online store. For a
                startup like mine, it has always been important to me that ExD
                is there when I need them for an urgent post or any off-hour
                client management. I have to say my experience with them has
                been really enjoyable! A job well done!"
              </h6>
            </div>
            <div className="owner">
              <h5>-- Hur -Owner --</h5>
            </div>
          </div>
          <div className="testimonial">
            <h3 className="title">
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" /> APWA{" "}
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
            </h3>
            <div className="content">
              <h6>
                "I thank ExD for their determination and hard work, in order to
                promote our products through their own platforms which helped us
                increase our consumer response rates! They have also been
                working on our website and complying with everything, changing
                the layouts and designs. They’ve been very patient and extremely
                helpful!"
              </h6>
            </div>
            <div className="owner">
              <h5>-- Amna Syed -Owner --</h5>
            </div>
          </div>
          <div className="testimonial">
            <h3 className="title">
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" /> AddOn{" "}
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
            </h3>
            <div className="content">
              <h6>
                "I had a great experience working with ExD Digital. For an old
                and well-known brand like Add-on, it was important that we get a
                digital and eCommerce partner who can bring fresh and creative
                ideas. Partnering with ExD was money well spent. The engagement
                rate of customers, both in-store and online, increased when ExD
                used their creative methods to promote my brand"
              </h6>
            </div>
            <div className="owner">
              <h5>-- Faraz Haroon -Owner --</h5>
            </div>
          </div>
          <div className="testimonial">
            <h3 className="title">
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" /> Matchless{" "}
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
              <FontAwesomeIcon icon={faCircle} className="i" />
            </h3>
            <div className="content">
              <h6>
                "I have been working upon creating a platform with ExD Design
                and have been continuously impressed by their expertise in
                developing different strategies in order to create improvement.
                They also create a flexible and enjoyable working environment"
              </h6>
            </div>
            <div className="owner">
              <h5>-- Faisal Qadeer -Owner --</h5>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default Section8;
