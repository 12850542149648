import React from "react";

import Section1 from "../includes/Thankyou/Section1";

import { Helmet } from "react-helmet";

const Thankyou = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "exddigital.com",
    },
    headline: "ExD Digital - Thankyou",
    image: "https://www.exddigital.com/images/thankyou/main-banner.jpg",
    author: {
      "@type": "Organization",
      name: "ExD Digital - Thankyou",
      url: "https://www.exddigital.com/thankyou",
    },
    publisher: {
      "@type": "Organization",
      name: "ExD Digital",
      logo: {
        "@type": "ImageObject",
        url: "https://www.exddigital.com/images/exd_digital.png",
      },
    },
    datePublished: "2023-08-07",
    dateModified: "2023-08-08",
  };

  return (
    <>
      <Helmet>
        <title>
          ExD Digital - Thankyou
        </title>
        <link rel="canonical" href="https://www.exddigital.com/thankyou" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main className="thankyou-main">
        <article className="thankyou">
          <Section1 url={devUrl} />
        </article>
      </main>
    </>
  );
};
export default React.memo(Thankyou);