import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Section1 = ({ url }) => {
  useEffect(() => {
    const spotlightSize = 400;

    function handleMouseMove(event) {
      if (window.event) {
        event = window.event;
      }
      let titleRect = document
        .querySelector(".hollow-txt")
        .getBoundingClientRect();
      let mouseX = event.clientX;
      let spotlightX = mouseX - spotlightSize / 2 - titleRect.left;
      let mouseY = event.clientY;
      let spotlightY = mouseY - spotlightSize / 2 - titleRect.top;
      const element = document.querySelector(".hollow-txt");
      element.style.backgroundPosition = spotlightX + "px " + spotlightY + "px";
    }

    document.addEventListener("mousemove", handleMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // to redirect to a different section on smaller screens

  useEffect(() => {
    const scrollLink = document.getElementById("scroll-link");

    function handleResize() {
      if (window.innerWidth < 992) {
        // Set the href for screens smaller than 992px to scroll to #our-work-section-2
        scrollLink.href = "#our-work-section-2";
      } else {
        // Set the href for larger screens to scroll to #our-work-section-3
        scrollLink.href = "#our-work-section-3";
      }
    }

    // Initial setup
    handleResize();

    // Listen for changes in screen width
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="our-work-section-1" style={{
      backgroundImage: "url(" + url + "images/our-work/main-banner.jpg)",
    }}>
        <div className="container sect-1-container col-12">
          <div
            className="txt-1 col-12"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <h1 className="white-txt">
              Let's Have <span className="yellow-text-span">a Look</span>
            </h1>
          </div>
          <div
            className="txt-2 col-12"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <p className="yellow-txt">
              <span className="white-text-span">at</span> What
            </p>
          </div>
          <div
            className="txt-3 col-12"
            data-aos="fade-up"
            data-aos-duration="1400"
          >
            <p className="hollow-txt">We've Done</p>
          </div>
          <div className="scroller">
            <p className="scroller-text">Scroll Down</p>
            <a
              id="scroll-link"
              href="#our-work-section-3"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faArrowDown} className="i" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
