import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const Section1 = ({ url }) => {
  useEffect(() => {
    const spotlightSize = 400;

    function handleMouseMove(event) {
      if (window.event) {
        event = window.event;
      }
      let titleRect = document
        .querySelector(".hollow-txt")
        .getBoundingClientRect();
      let mouseX = event.clientX;
      let spotlightX = mouseX - spotlightSize / 2 - titleRect.left;
      let mouseY = event.clientY;
      let spotlightY = mouseY - spotlightSize / 2 - titleRect.top;
      const element = document.querySelector(".hollow-txt");
      element.style.backgroundPosition = spotlightX + "px " + spotlightY + "px";
    }

    document.addEventListener("mousemove", handleMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <section
        className="thankyou-section-1"
        style={{
          backgroundImage: "url(" + url + "images/thankyou/main-banner.jpg)",
        }}
      >
        <div className="container sect-1-container col-12">
          <div
            className="txt-3 col-12"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            <h1 className="hollow-txt">Thankyou</h1>
          </div>
          <div className="thankyou-phrase">
            <p className="thankyou-text">Our team will get back to you soon!</p>
            <Link to="/" className="btn home-sec-2-btn">
              Homepage
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
