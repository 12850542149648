import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowRight
} from "@fortawesome/free-solid-svg-icons";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section3 = ({ url }) => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1900,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    centerMode: true, // Add this property
    centerPadding: "0px", // Adjust the value as per your requirement
  };

  return (
    <>
      <section className="home-section-3">
        <div className="container sect-3-container">
          <p
            className="sect-3-heading"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            Our Work
          </p>
          <p
            className="sect-3-title"
            data-aos="fade-up"
            data-aos-duration="1200"
          >
            Featured Projects
          </p>
          <p className="sect-3-txt" data-aos="fade-up" data-aos-duration="1400">
            Through our various services, which utilize web applications, mobile
            technology and digital media, we have helped organizations across
            various industries from FMCG, Oil & Gas, Retail, Textile, Financial
            Services to Education and Non-profit in becoming market leaders.
          </p>
          <div className="container portfolio-container">
            <div
              className="row portfolio-row"
              data-aos="fade-down"
              data-aos-duration="1600"
            >
              <Slider {...settings}>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/1.jpg"}
                    alt="Cosmetic Skin"
                  />
                </div>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/2.jpg"}
                    alt="ExD"
                  />
                </div>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/3.jpg"}
                    alt="Gitex"
                  />
                </div>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/4.jpg"}
                    alt="AURRAH"
                  />
                </div>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/5.jpg"}
                    alt="ExD"
                  />
                </div>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/6.jpg"}
                    alt="Fauji Foods"
                  />
                </div>
                <div className="portfolio col-lg-4 col-md-4 col-12">
                  <img loading="lazy"
                    src={url + "images/homepage/featured_projects/7.jpg"}
                    alt="PSO"
                  />
                </div>
              </Slider>
            </div>
          </div>
          <div className="projects">
            <Link className="projects-link" to="/our-work">
              <FontAwesomeIcon icon={faCircleArrowRight} className="i" />
              <p>View All Projects</p>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
