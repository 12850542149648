import React, { useState, useEffect } from "react";

const Section2 = ({ url }) => {
  const [displayDots, setDisplayDots] = useState(true);
  const [displayText, setDisplayText] = useState("none");
  const [buttonText, setButtonText] = useState("Read More");

  const handleButtonClick = () => {
    if (displayDots) {
      setDisplayDots(false);
      setButtonText("Read Less");
      setDisplayText("block");
    } else {
      setDisplayDots(true);
      setButtonText("Read More");
      setDisplayText("none");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 2500) {
        setDisplayText("none");
      } else {
        setDisplayText("block");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial display state based on window width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="about-section-2">
      <div className="container sect-2-container">
        <div className="row sect-2-row">
          <div className="col-lg-5 col-md-5 col-sm-12 left-section">
            <img
              loading="lazy"
              src={url + "images/about-us/2.jpg"}
              className="left-img"
              alt="About our Services"
              data-aos="fade-right"
              data-aos-duration="1000"
            />
          </div>
          <div className="col-lg-7 col-md-7 col-sm-12 right-section">
            <div className="txt">
              <h5 data-aos="fade-left" data-aos-duration="1000">
                <strong style={{ color: "#fcb61d" }}>Our Expertise - </strong>
                ExD Digital specializes in three core areas:
                <br />
                <br />
                <strong style={{ color: "#fcb61d" }}>
                  Website Development:
                </strong>
                <br />
                <br />
                A strong online presence starts with a well-crafted website. We
                design and develop cutting-edge websites that are visually
                appealing and perform seamlessly. Our websites are optimized for
                performance, SEO, and user experience, ensuring your brand
                stands out in the digital crowd. Whether it's an E-Commerce
                site, a corporate portal, or a personal blog, we have the skills
                and experience to bring your vision to life. Our experience
                speaks for itself, with a diverse portfolio of successful
                projects significantly impacting businesses like Shahalami.pk,
                Rungrez, Qalakmar, Qarshi Research Institute, Panasian Group,
                ArtSoch, Financial Monitoring Unit, and many others.
                <br />
                <br />
                <strong style={{ color: "#fcb61d" }}>App Development:</strong>
                <br />
                <br />
                In today's mobile-first world, having a well-designed and
                functional app can make all the difference. Our team excels in
                creating user-friendly and feature-rich mobile applications for
                iOS and Android platforms. We have ensured that apps for
                entities like Pakistan State Oil, Qarshi Research Institute,
                Fauji Foods Limited, Shahalami.pk, and countless others meet and
                exceed user expectations.
                <br />
                <br />
                <span id="dots"></span>
                <span id="more" style={{ display: displayText }}>
                  
                  <br />
                  <strong style={{ color: "#fcb61d" }}>
                    Social Media Marketing:
                  </strong>
                  <br />
                  <br />
                  We understand the power of social media in the digital age.
                  Our team of social media experts knows how to harness the
                  potential of platforms like Facebook, Instagram, Twitter, and
                  more to create engaging campaigns that drive results. Our
                  track record speaks for itself, with clients like
                  Shahalami.pk, Rungrez, Excellence Delivered Pvt Ltd, ExD
                  Education, Hummingbirds, Qalamkar, Nayna Fashion House, Close
                  Comfort and others benefitting from our tailored social media
                  strategies.
                  <br />
                  <br />
                </span>
                {window.innerWidth < 2500 && (
                  <button
                    onClick={handleButtonClick}
                    id="myBtn"
                    className="myBtn"
                  >
                    {buttonText}
                  </button>
                )}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
