import React from "react";

import Section1 from "../includes/OurWork/Section1";
// import Section2 from "../includes/OurWork/Section2";
import Section3 from "../includes/OurWork/Section3";
import Section4 from "../includes/OurWork/Section4";
import Section6 from "../includes/OurWork/Section6";

// form from homepage

import Section5 from "../includes/Home/Section5";
import Section9 from "../includes/Home/Section9";

import { Helmet } from "react-helmet";

const OurWork = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "exddigital.com",
    },
    headline: "ExD Digital - Our Work",
    image: "https://www.exddigital.com/images/our-work/main-banner.jpg",
    author: {
      "@type": "Organization",
      name: "ExD Digital - Our Work",
      url: "https://www.exddigital.com/our-work",
    },
    publisher: {
      "@type": "Organization",
      name: "ExD Digital",
      logo: {
        "@type": "ImageObject",
        url: "https://www.exddigital.com/images/exd_digital.png",
      },
    },
    datePublished: "2023-08-03",
    dateModified: "2023-08-04",
  };

  return (
    <>
      <Helmet>
        <title>ExD Digital - Our Work</title>
        <link rel="canonical" href="https://www.exddigital.com/our-work" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main className="our-work-main">
        <article className="portfolio">
          <Section1 url={devUrl} />
          {/* <Section2 url={devUrl} /> */}
          <Section4 url={devUrl} />
          <Section3 url={devUrl} />
          <Section6 url={devUrl} />
          <div className="sec-5">
            <Section5 url={devUrl} />
          </div>
          <Section9 url={devUrl} />
        </article>
      </main>
    </>
  );
};
export default React.memo(OurWork);
