import React from "react";
import "./App.scss";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";

import ProgressBar from "./components/partials/Animations/ProgressBar";
import CustomCursor from "./components/partials/Animations/CustomCursor";
import ScrolltoTop from "./components/partials/ScrolltoTop/ScrolltoTop";
import ScrollToTopOnLoad from "./components/partials/ScrolltoTop/ScrollToTopOnLoad";

import Navbar from "./components/partials/Navbar/Navbar";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import Services from "./components/pages/Services";
import MeetTheTeam from "./components/pages/MeetTheTeam";
import OurWork from "./components/pages/OurWork";
import ContactUs from "./components/pages/ContactUs";
import Thankyou from "./components/pages/Thankyou";
import Footer from "./components/partials/Footer/Footer";

AOS.init({
  once: true, // keep it true if you want to load AOS animations only once, otherwise set it to false
});

const App = () => {
  // var devUrl = "http://localhost:3000/";
   var devUrl = "https://www.exddigital.com/";
  // var devUrl = "https://www.exddigital.com/";

  return (
    // <Router basename="/exd-digital-website">
    <Router basename="/">
      <div className="div">
        {/* call to te progress bar on all pages */}
        <ProgressBar />
        {/* call to custom cursor all pages */}
        <CustomCursor />
        {/* scroll to top button */}
        <ScrolltoTop />
        {/*  scroll to top on page load */}
        <ScrollToTopOnLoad />

        <div className="body">
          <Navbar />

          <div className="wrap">
            <Routes>
              <Route path="/" exact element={<Home devUrl={devUrl} />} />
              <Route 
                path="/about-us"
                exact
                element={<AboutUs devUrl={devUrl} />}
              />
              <Route
                path="/services"
                exact
                element={<Services devUrl={devUrl} />}
              />
              <Route
                path="/meet-the-team"
                exact
                element={<MeetTheTeam devUrl={devUrl} />}
              />
              <Route
                path="/our-work"
                exact
                element={<OurWork devUrl={devUrl} />}
              />
              <Route
                path="/contact-us"
                exact
                element={<ContactUs devUrl={devUrl} />}
              />
              <Route
                path="/thankyou"
                exact
                element={<Thankyou devUrl={devUrl} />}
              />
            </Routes>
          </div>

          <Footer />
        </div>
      </div>
    </Router>
  );
};

export default App;
