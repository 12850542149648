import React from "react";

import Section1 from "../includes/Services/Section1";
import Section2 from "../includes/Services/Section2";

// form from homepage

import Section9 from "../includes/Home/Section9";

import { Helmet } from "react-helmet";

const Services = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "exddigital.com",
    },
    headline: "ExD Digital - Services",
    image: "https://www.exddigital.com/images/services/main-banner.jpg",
    author: {
      "@type": "Organization",
      name: "ExD Digital - Services",
      url: "https://www.exddigital.com/services",
    },
    publisher: {
      "@type": "Organization",
      name: "ExD Digital",
      logo: {
        "@type": "ImageObject",
        url: "https://www.exddigital.com/images/exd_digital.png",
      },
    },
    datePublished: "2023-08-04",
    dateModified: "2023-08-05",
  };
  return (
    <>
      <Helmet>
        <title>ExD Digital - Services</title>
        <link rel="canonical" href="https://www.exddigital.com/services" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main className="services-main">
        <article className="services">
          <Section1 url={devUrl} />
          <Section2 url={devUrl} />

          <Section9 url={devUrl} />
        </article>
      </main>
    </>
  );
};
export default React.memo(Services);
