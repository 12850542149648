import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  // to open email in mail
  const emailAddress = "social@exdnow.com"
  return (
    <footer className="footer">
      <div className="container footer-section-1">
        <div className="row footer-row footer-sect-1-row">
          <div className="footer-logo col-7">
            <Link to="/" className="footer-logo-link">
              <img loading="lazy"
                src={
                  "https://www.exddigital.com/images/exd_digital.png"
                }
              alt="ExD Digital"
                className="footer-logo-img"
                style={{ height: "auto" }}
              />
            </Link>
          </div>
          <div className="social-list col-5">
            <a
              href="https://www.facebook.com/ExDDigital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-facebook"></i>
            </a>
            <a
              href="https://www.instagram.com/exd_digital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-instagram"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/exddigital/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa-brands fa-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="container footer-section-2">
        <hr className="footer-line" />
      </div>
      <div className="container footer-section-3">
        <div className="row footer-row footer-sect-3-row">
          <div className="footer-left-links col-xl-4 col-lg-4 col-md-4 col-6">
            <div className="left-links">
              {/* <p>Services</p> */}
              <p>Home</p>
              <ul>
                {/* <li>
                  <Link to="/services">E-Commerce</Link>
                </li> */}
                {/* <li>
                  <Link to="/services">Web & App Development</Link>
                </li>
                <li>
                  <Link to="/services">Graphic Design</Link>
                </li>
                <li>
                  <Link to="/services">Digital Marketing</Link>
                </li> */}
                {/* <li>
                  <Link to="/services">SEO</Link>
                </li> */}
                {/* <li>
                  <Link to="/services">Data Analytics</Link>
                </li> */}
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/meet-the-team">Our Team</Link>
                </li>
                <li>
                  <Link to="/our-work">Our Work</Link>
                </li>
                <li>
                  <Link to="/contact-us">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-middle-links col-xl-4 col-lg-4 col-md-4 col-6">
            <div className="middle-links">
              {/* <p>Case Studies</p>
              <ul>
                <li>
                  <Link to="/our-work">Brain Box</Link>
                </li>
                <li>
                  <Link to="/our-work">Pakistan State Oil</Link>
                </li>
                <li>
                  <Link to="/our-work">Rungrez</Link>
                </li>
                <li>
                  <Link to="/our-work">Pepsi Co.</Link>
                </li>
              </ul> */}
              <p>Services</p>
              <ul>
                {/* <li>
                  <Link to="/services">E-Commerce</Link>
                </li> */}
                <li>
                  <Link to="/services">App & Web Development</Link>
                </li>
                <li>
                  <Link to="/services">AI Projects</Link>
                </li>
                <li>
                  <Link to="/services">Social Media Marketing</Link>
                </li>
                {/* <li>
                  <Link to="/services">SEO</Link>
                </li> */}
                {/* <li>
                  <Link to="/services">Data Analytics</Link>
                </li> */}
                <li>
                  <Link to="/services">Branding</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="footer-right-content col-xl-4 col-lg-4 col-md-4 col-6">
            <div className="right-content">
              <p className="right-content-title">About</p>
              <p className="right-content-text">
                Want to know about us
                <br />
                Contact Us: 0423-5408548
                <br />
                Email: <a href={`mailto:${emailAddress}`}>{emailAddress}</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
