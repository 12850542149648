import React from "react";

const Section4 = ({ url }) => {
  return (
    <>
      <section className="team-section-4">
        <div
          className="container about-team"
          data-aos="fade-down"
          data-aos-duration="1000"
        >
          <h5>
            Our team of professionals is a dynamic blend of experienced
            strategists, innovative thinkers, and dedicated individuals.
            Passionate about harnessing the power of the digital space to drive
            results, they are a wealth of talent. Their collaborative and
            proactive approach shines through as they craft creative solutions
            with meticulous attention to detail. Customer-centric to the core,
            they are passionate about delivering data-driven and strategically
            sound results. Whether crafting compelling social media campaigns,
            building user-friendly apps and websites, or developing data-driven
            portals, our team's strategic approach, problem-solving abilities,
            and dedication to excellence ensure they deliver impactful and
            innovative solutions to fit unique requirements.
          </h5>
        </div>
      </section>
    </>
  );
};

export default Section4;
