import React, { useEffect } from "react";

const CustomCursor = () => {
  useEffect(() => {
    const rjs_cursor = document.getElementById("rjs_cursor");
    const body = document.querySelector("body");

    const showCursor = () => {
      if (rjs_cursor.classList.contains("rjs_cursor_hidden")) {
        rjs_cursor.classList.remove("rjs_cursor_hidden");
      }
      rjs_cursor.classList.add("rjs_cursor_visible");
    };

    const hideCursor = () => {
      if (rjs_cursor.classList.contains("rjs_cursor_visible")) {
        rjs_cursor.classList.remove("rjs_cursor_visible");
      }
      rjs_cursor.classList.add("rjs_cursor_hidden");
    };

    const moveCursor = (e) => {
      showCursor();

      const rjs_cursor_width = rjs_cursor.offsetWidth * 0.5;
      const rjs_cursor_height = rjs_cursor.offsetHeight * 0.5;

      const rjs_cursor_x = e.clientX - rjs_cursor_width;
      const rjs_cursor_y = e.clientY - rjs_cursor_height;
      const rjs_cursor_pos = `translate(${rjs_cursor_x}px, ${rjs_cursor_y}px)`;
      rjs_cursor.style.transform = rjs_cursor_pos;
    };

    const hoverCursor = () => {
      rjs_cursor.classList.add("rjs_cursor_hover");
    };

    const unhoverCursor = () => {
      rjs_cursor.classList.remove("rjs_cursor_hover");
    };

    window.addEventListener("mousemove", moveCursor);
    body.addEventListener("mouseleave", hideCursor);

    const elementsToHover = document.querySelectorAll(
      "a, button, input, .link, Link, .btn, .i, .toggle, .portfolio, .counter-1, .counter-2, .counter-3, .column, .main-div, i, .m-name, .m-designation, .m-img, .slick-arrow, .map, .video, .v-col"
      // Update the selectors here
    );

    elementsToHover.forEach((item) => {
      item.addEventListener("mouseover", hoverCursor);
      item.addEventListener("mouseleave", unhoverCursor);
    });

    return () => {
      window.removeEventListener("mousemove", moveCursor);
      body.removeEventListener("mouseleave", hideCursor);

      elementsToHover.forEach((item) => {
        item.removeEventListener("mouseover", hoverCursor);
        item.removeEventListener("mouseleave", unhoverCursor);
      });
    };
  }, []);

  return (
    <div id="rjs_cursor" className="rjs-cursor">
      <div className="rjs-cursor-icon" />
    </div>
  );
};

export default CustomCursor;
