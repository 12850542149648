import React from "react";

const Section3 = ({ url }) => {
  return (
    <>
      <section className="our-work-section-4" id="our-work-section-4">
        <div
          className="container-fluid sect-4-container"
          data-aos="fade-up"
          data-aos-duration="1200"
        >
          <div className="row sec-4-row">
            {/* <div className="col-lg-8 col-md-8 col-12 left-txt">
              {/* <p className="glow-txt">Design that stands out</p> */}
            {/* <div class="patterns">
                <svg width="100%" height="100%">
                  <text x="50%" y="60%" text-anchor="middle">
                    Design that stands out
                  </text>
                </svg>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-12 right-txt">
              <p className="changing-txt">
                -ExD Digital is a unique <span>Design & Development</span> place
                based in Lahore, and we're always pushing forward, to help you
                achieve your business goals-
              </p>
            </div> */}
            {/* <div className="col-12 right-txt">
              <p className="changing-txt">
                -ExD Digital is a unique <span>Design & Development</span> place
                based in Lahore, and we're always pushing forward, to help you
                achieve your business goals-
              </p>
            </div> */}
            <div className="col-12 right-txt">
              <p className="changing-txt">
                <span>-</span> ExD Digital is a uniquely innovative enterprise continuously
                crafting cutting-edge solutions through customized{" "}
                <span>design</span> and <span>development</span> services <span>-</span>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
