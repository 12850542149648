import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Section3 = ({ url }) => {
  var settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1160,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 580,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    centerMode: true, // Add this property
    centerPadding: "0px", // Adjust the value as per your requirement
  };

  return (
    <>
      <section className="team-section-3" id="team-section-3">
        <div
          className="team-container"
          data-aos="fade-in"
          data-aos-duration="1000"
        >
          <div className="team-title">
            <p>Leadership Team</p>
          </div>
          <div className="team-cards-row">
            <Slider {...settings}>
              <div className="team-member">
                <div className="m-name">Nailah Syed</div>
                <div className="m-designation">Director</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Maam-Nailah.png"}
                    alt="Nailah Syed"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Ziaullah Mughal</div>
                <div className="m-designation">Delivery Manager - Digital</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Zia-sb.png"}
                    alt="Ziaullah Mughal"
                  />
                </div>
              </div>
            
              <div className="team-member">
                <div className="m-name">Mehboob Ur Rehman</div>
                <div className="m-designation">Digital Marketing Assc.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Mehboob.png"}
                    alt="Mehboob Ur Rehman"
                  />
                </div>
              </div>
           
              <div className="team-member">
                <div className="m-name">Muhammad Sajeel</div>
                <div className="m-designation">Sr. Android Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Sajeel.png"}
                    alt="Muhammad Sajeel"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Moeez Ahmad Dar</div>
                <div className="m-designation">Sr. Web Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Moeez.png"}
                    alt="Moeez Ahmed"
                  />
                </div>
              </div>
           
              <div className="team-member">
                <div className="m-name">Umar Ashraf</div>
                <div className="m-designation">Web Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Umer.png"}
                    alt="Umar Ashraf"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Naveed Akram</div>
                <div className="m-designation">Web Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Naveed.png"}
                    alt="Naveed Akram"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Ahmad Maaz</div>
                <div className="m-designation">Embedded  Sys Eng.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/AhmedMaaz.jpeg"}
                    alt="Ahmad Maaz"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Zubair Asif</div>
                <div className="m-designation">Web Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/ZubairAsif.jpeg"}
                    alt="Zubair Asif"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Syed Haseeb Ul Hassan</div>
                <div className="m-designation">Jr. Flutter Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/Haseeb.png"}
                    alt="Haseeb Ul Hassan"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Jawad Zahoor</div>
                <div className="m-designation">Web Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/JawadZahoor.jpeg"}
                    alt="Jawad Zahoor"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Moiz Ahmad</div>
                <div className="m-designation">Jr. Web Dev.</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/MoeezAhmed.jpeg"}
                    alt="Moeez Ahmad"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Fatika Amir</div>
                <div className="m-designation">Graphic Designer</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/FatikaAmir.jpeg"}
                    alt="Fatika Amir"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Usama Khalid</div>
                <div className="m-designation">Graphic Designer</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/OsamaKhalid.jpeg"}
                    alt="Osama Khalid"
                  />
                </div>
              </div>
              <div className="team-member">
                <div className="m-name">Danyal Tariq</div>
                <div className="m-designation">Content Writer</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/DanyalTariq.jpeg"}
                    alt="Danyal Tariq"
                  />
                </div>
              </div>
              {/* <div className="team-member">
                <div className="m-name">Shayaan Viqar</div>
                <div className="m-designation">Marketing Manager</div>
                <div className="m-img">
                  <img loading="lazy"
                    src={url + "images/our-team/team-member-1.png"}
                    alt="Nailah Syed"
                  />
                </div>
              </div> */}
            </Slider>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section3;
