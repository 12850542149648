import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";

const Section4 = ({ url }) => {
  const [currentColor, setCurrentColor] = useState("white");

  useEffect(() => {
    const handle_Scroll = () => {
      const panels = document.querySelectorAll(".panel");
      const scroll = window.scrollY + window.innerHeight / 2;

      panels.forEach((panel) => {
        if (
          panel.offsetTop <= scroll &&
          panel.offsetTop + panel.offsetHeight > scroll
        ) {
          const newColor = panel.dataset.color;
          setCurrentColor(newColor);
        }
      });
    };

    window.addEventListener("scroll", handle_Scroll);
    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  // for read more button
  const [displayDots, setDisplayDots] = useState(true);
  const [displayText, setDisplayText] = useState("none");
  const [buttonText, setButtonText] = useState("Read More");

  const handleButtonClick = () => {
    if (displayDots) {
      setDisplayDots(false);
      setButtonText("Read Less");
      setDisplayText("block");
    } else {
      setDisplayDots(true);
      setButtonText("Read More");
      setDisplayText("none");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 0) {
        setDisplayText("none");
      } else {
        setDisplayText("block");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial display state based on window width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="home-section-4">
      <div className="transition-container sect-4-container">
        <div
          className={`panel color-${currentColor} black-panel`}
          data-color="black"
        >
          <div className="container">
            <div className="row black-panel-row">
              <div
                className="col-lg-7 col-md-7 col-sm-12 black-panel-left child"
                data-aos="fade-right"
                data-aos-duration="1000"
              >
                <p className="black-panel-txt">
                  ExD Digital ensures that a brand’s name stays highlighted and
                  optimized. We design digital marketing schemes, providing
                  end-to-end commerce services, including E-Commerce and custom
                  site development on platforms like Shopify, Magento, PHP,
                  HTML, Laravel, WordPress (CMS). Mobile app development for iOS
                  and Android platforms, back-end production, inventory, POS
                  systems and HRMS are our forte.
                  <br />
                  <br />
                  Our experienced web & app development team creates unique
                  online identities for businesses to obtain tangible results.
                </p>
                <span id="dots"></span>
                <span
                  id="more"
                  className="read-more-left-txt"
                  style={{ display: displayText }}
                >
                  We customize the perfect solutions to suit our clients’
                  business objectives and convert website traffic into sales by
                  utilizing effective website & search engine optimization,
                  modern visuals, and visitor analytics.
                  <br />
                  <br />
                </span>
                <button onClick={handleButtonClick} className="home-sec-4-btn">
                  {buttonText}
                </button>
              </div>
              <div
                className="col-lg-5 col-md-5 col-sm-12 black-panel-right child"
                data-aos="fade-left"
                data-aos-duration="1000"
              >
                <img
                  loading="lazy"
                  src={url + "images/homepage/clients.png"}
                  alt="Our Clients"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`panel color-${currentColor} white-panel`}
          data-color="white"
        >
          <div className="container">
            <div className="row white-panel-row">
              <div className="col-lg-10 col-md-10 col-sm-12 white-panel-left">
                <p
                  className="white-panel-heading"
                  data-aos="fade-right"
                  data-aos-duration="1000"
                >
                  Services
                </p>
                <p
                  className="white-panel-title"
                  data-aos="fade-right"
                  data-aos-duration="1200"
                >
                  Areas of Focus
                </p>
                <p
                  className="white-panel-txt"
                  data-aos="fade-right"
                  data-aos-duration="1400"
                >
                  We craft engaging brands and digital experiences that define
                  culture and elevate brands to achieve unprecedented growth.
                  Since day one, our focus has been on providing strategies and
                  services that position our clients to succeed in today’s
                  dynamic, constantly evolving world.
                </p>
              </div>
              <div
                className="col-lg-1 col-md-1 col-sm-12 white-panel-right"
                data-aos="zoom-in"
                data-aos-duration="1400"
              >
                <Link className="projects-link" to="/services">
                  <FontAwesomeIcon icon={faCircleArrowRight} className="i" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section4;
