import React from "react";

const Section6 = ({ url }) => {
  return (
    <>
      <section className="our-work-section-6">
        <div
          className="container-fluid sect-6-container"
          data-aos="fade-in"
          data-aos-duration="1200"
        >
          <p className="descp">
            <span className="span-dash">-</span> We are creatives who dwell in
            the fields of <span className="span-txt">design</span>,{" "}
            <span className="span-txt">animation</span>,{" "}
            <span className="span-txt">strategy</span> &{" "}
            <span className="span-txt">tech</span>. We use powerful insights,
            research and our unique POV as the basis of every project{" "}
            <span className="span-dash">-</span>
          </p>
        </div>
      </section>
    </>
  );
};

export default Section6;
