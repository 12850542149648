import React from "react";

import Section1 from "../includes/Home/Section1";
import Section2 from "../includes/Home/Section2";
import Section3 from "../includes/Home/Section3";
import Section4 from "../includes/Home/Section4";
import Section5 from "../includes/Home/Section5";
import Section6 from "../includes/Home/Section6";
import Section7 from "../includes/Home/Section7";
import Section8 from "../includes/Home/Section8";
import Section9 from "../includes/Home/Section9";

import { Helmet } from "react-helmet";

const Home = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "exddigital.com",
    },
    headline: "ExD Digital - Digital Marketing & Custom Development Solutions",
    image: "https://www.exddigital.com/images/homepage/bg-section-1.jpg",
    author: {
      "@type": "Organization",
      name: "ExD Digital",
      url: "https://www.exddigital.com",
    },
    publisher: {
      "@type": "Organization",
      name: "ExD Digital",
      logo: {
        "@type": "ImageObject",
        url: "https://www.exddigital.com/images/exd_digital.png",
      },
    },
    datePublished: "2023-08-01",
    dateModified: "2023-08-02",
  };

  return (
    <>
      <Helmet>
        <title>
          ExD Digital - Digital Marketing & Custom Development Solutions
        </title>
        <meta
          name="description"
          content="Book a demo to get more leads or grow your startups, e-commerce, or service businesses with 360° digital marketing services & custom web/app development solutions"
        />
        <link rel="canonical" href="https://www.exddigital.com/" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main className="home-main">
        <article className="home">
          <Section1 url={devUrl} />
          <Section2 url={devUrl} />
          <Section3 url={devUrl} />
          <Section4 url={devUrl} />
          <Section5 url={devUrl} />
          <Section6 url={devUrl} />
          <Section7 url={devUrl} />
          <Section8 url={devUrl} />
          <Section9 url={devUrl} />
        </article>
      </main>
    </>
  );
};
export default React.memo(Home);
