import React from "react";

const Section6 = ({ url }) => {
  return (
    <section className="home-section-6">
      <div className="container sect-6-container">
        <div className="row">
          <div className="col-lg-7 col-md-7 col-sm-12 left-txt child">
            <div className="title" data-aos="fade-right" aos-duration="1200">
              <p>
                Excellence in Service
                <br />
                is Our Passion
              </p>
            </div>
            <div className="content" data-aos="fade-right" aos-duration="1400">
              <p>
                As a one-stop digital services provider, we strive to offer a
                unique and innovative solution that can transform your business.
                Our diverse experience across various industries enables us to
                cater to all kinds of digital needs.
              </p>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 right-img child"
            data-aos="fade-down"
            aos-duration="1400"
          >
            <img
              loading="lazy"
              src={url + "images/homepage/podcast.jpg"}
              alt="Podcast"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section6;
