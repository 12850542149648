import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const Section2 = ({ url }) => {
  // color transition
  const [currentColor, setCurrentColor] = useState("black");

  useEffect(() => {
    const handle_Scroll = () => {
      const panels = document.querySelectorAll(".panel");
      const scroll = window.scrollY + window.innerHeight / 4.5;

      panels.forEach((panel) => {
        if (
          panel.offsetTop <= scroll &&
          panel.offsetTop + panel.offsetHeight > scroll
        ) {
          const newColor = panel.dataset.color;
          setCurrentColor(newColor);
        }
      });
    };

    window.addEventListener("scroll", handle_Scroll);
    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  // for read more column
  const [displayText1, setDisplayText1] = useState("none");
  const [buttonText1, setButtonText1] = useState("Read More");

  const [displayText2, setDisplayText2] = useState("none");
  const [buttonText2, setButtonText2] = useState("Read More");

  const [displayText3, setDisplayText3] = useState("none");
  const [buttonText3, setButtonText3] = useState("Read More");

  const [displayText4, setDisplayText4] = useState("none");
  const [buttonText4, setButtonText4] = useState("Read More");

  const handleButtonClick1 = () => {
    if (displayText1 === "none") {
      setDisplayText1("block");
      setButtonText1("Read Less");
    } else {
      setDisplayText1("none");
      setButtonText1("Read More");
    }
  };

  const handleButtonClick2 = () => {
    if (displayText2 === "none") {
      setDisplayText2("block");
      setButtonText2("Read Less");
    } else {
      setDisplayText2("none");
      setButtonText2("Read More");
    }
  };

  const handleButtonClick3 = () => {
    if (displayText3 === "none") {
      setDisplayText3("block");
      setButtonText3("Read Less");
    } else {
      setDisplayText3("none");
      setButtonText3("Read More");
    }
  };

  const handleButtonClick4 = () => {
    if (displayText4 === "none") {
      setDisplayText4("block");
      setButtonText4("Read Less");
    } else {
      setDisplayText4("none");
      setButtonText4("Read More");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDisplayText1("none");
        setDisplayText2("none");
        setDisplayText3("none");
        setDisplayText4("none");
      } else {
        setDisplayText1("block");
        setDisplayText2("block");
        setDisplayText3("block");
        setDisplayText4("block");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial display state based on window width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="services-section-2">
      <div className="transition-container sect-2-container">
        <div
          className={`panel color-${currentColor} black-panel panel-1`}
          data-color="black"
        >
          <div className="container panel-1-container">
            <div className="services-desc">
              <h5 className="txt">
                At ExD Digital, we take immense pride in the projects we've
                undertaken and the success stories we've created for our
                clients. Our portfolio is a testament to our commitment to
                excellence and innovation in the digital realm.
                <br />
                <br />
                Explore our diverse projects, from cutting-edge mobile apps and
                visually stunning websites to data-driven AI solutions and
                dynamic social media campaigns. Each project exemplifies our
                dedication to crafting compelling digital experiences and
                delivering tangible results.
                <br />
                <br />
                Browse our portfolio to witness the transformative power of our
                expertise and envision how we can collaborate to bring your
                ideas to life in the digital world.
              </h5>
            </div>
          </div>
        </div>
        <div
          className={`panel color-${currentColor} white-panel panel-2`}
          data-color="white"
        >
          <div className="container panel-2-container" id="services-section">
            <div className="row panel-2-row">
              <div className="col-lg-6 col-md-6 col-12 service">
                <div className="srvc-img">
                  <img
                    loading="lazy"
                    src={url + "images/services/app-web-dev.jpg"}
                    alt="App & Web Development"
                  />
                </div>
                <div className="srvc-title">
                  <p>App & Web Development </p>
                </div>
                <div className="srvc-desc">
                  <span style={{ display: displayText1 }}>
                    <p>
                      We specialize in creating digital destinations that
                      captivate your audience. Whether you need a web portal, a
                      mobile app, or a stunning website, our team of seasoned
                      developers, designers, and UX/UI experts work
                      collaboratively to bring your vision to life. From
                      user-centric design to seamless functionality, we ensure
                      your online presence aligns perfectly with your brand
                      identity.
                    </p>
                  </span>
                  {window.innerWidth < 768 && (
                    <button
                      onClick={handleButtonClick1}
                      id="myBtn"
                      className="myBtn"
                    >
                      {buttonText1}{" "}
                      <FontAwesomeIcon icon={faArrowRight} className="i" />
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 service">
                <div className="srvc-img">
                  <img
                    loading="lazy"
                    src={url + "images/services/ai.jpg"}
                    alt="AI-Powered Projects"
                  />
                </div>
                <div className="srvc-title">
                  <p> AI-Powered Projects</p>
                </div>
                <div className="srvc-desc">
                  <span style={{ display: displayText2 }}>
                    <p>
                      Embrace the future with cutting-edge AI solutions. Our AI
                      experts leverage machine learning and deep learning to
                      develop intelligent systems that enhance user experiences,
                      streamline processes, and drive business growth. Whether
                      it's chatbots, recommendation engines, or predictive
                      analytics, we harness the power of AI to make your digital
                      presence more innovative and efficient.
                      {/* Grab your potential customer’s attention with creative &
                      consistent branding across all platforms to stand out in
                      the crowd. So, <span className="bold">Be Bold</span>,{" "}
                      <span className="light">Be Light</span>,{" "}
                      <span className="italic">Be Italic</span>, but never be{" "}
                      <span className="regular">Regular</span>. */}
                    </p>
                  </span>
                  {window.innerWidth < 768 && (
                    <button
                      onClick={handleButtonClick2}
                      id="myBtn"
                      className="myBtn"
                    >
                      {buttonText2}{" "}
                      <FontAwesomeIcon icon={faArrowRight} className="i" />
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 service">
                <div className="srvc-img">
                  <img
                    loading="lazy"
                    src={url + "images/services/smm.jpg"}
                    alt="Social Media Marketing"
                  />
                </div>
                <div className="srvc-title">
                  <p>Social Media Marketing</p>
                </div>
                <div className="srvc-desc">
                  <span style={{ display: displayText3 }}>
                    <p>
                      Social media is the heartbeat of modern marketing. Our
                      social media mavens craft data-driven campaigns tailored
                      to your unique audience, delivering engaging content and
                      measurable results. We don't just aim to boost your online
                      presence; we focus on generating quality leads that
                      convert into loyal customers.
                    </p>
                  </span>
                  {window.innerWidth < 768 && (
                    <button
                      onClick={handleButtonClick3}
                      id="myBtn"
                      className="myBtn"
                    >
                      {buttonText3}{" "}
                      <FontAwesomeIcon icon={faArrowRight} className="i" />
                    </button>
                  )}
                </div>
              </div>
              <div className="col-lg-6 col-md-6 col-12 service">
                <div className="srvc-img">
                  <img
                    loading="lazy"
                    src={url + "images/services/branding.jpg"}
                    alt="Branding"
                  />
                </div>
                <div className="srvc-title">
                  <p>Branding</p>
                </div>
                <div className="srvc-desc">
                  <span style={{ display: displayText4 }}>
                    <p>
                      Your brand is your essence, and we're here to help you
                      define it. From creating compelling brand identities to
                      comprehensive branding strategies, we ensure that your
                      brand story is compelling, consistent, and memorable. Let
                      us transform your brand into a magnetic force in the
                      digital world.
                    </p>
                  </span>
                  {window.innerWidth < 768 && (
                    <button
                      onClick={handleButtonClick4}
                      id="myBtn"
                      className="myBtn"
                    >
                      {buttonText4}{" "}
                      <FontAwesomeIcon icon={faArrowRight} className="i" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section2;
