import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Section9 = ({ url }) => {
  // for hollow text in input field
  useEffect(() => {
    const spotlightSize = 100;

    function handleMouseMove(event) {
      if (window.event) {
        event = window.event;
      }
      let titleRect = document
        .querySelector(".email-field")
        .getBoundingClientRect();
      let mouseX = event.clientX;
      let spotlightX = mouseX - spotlightSize / 2 - titleRect.left;
      let mouseY = event.clientY;
      let spotlightY = mouseY - spotlightSize / 2 - titleRect.top;
      const element = document.querySelector(".email-field");
      element.style.backgroundPosition = spotlightX + "px " + spotlightY + "px";
    }

    document.addEventListener("mousemove", handleMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // to redirect form to the portfolio page and clear email field

  const [email, setEmail] = useState("");
  const formLink = "https://cloud.exdnow.com/s/2RqAqAwdH8yPmmg"; // Replace with your desired link

  const handleClick = () => {
    // Clear the input field by updating its state
    setEmail("");

    // window.open(formLink, "_blank"); // Open link in a new tab or window
    // Open the provided link in a new tab or window
    const newWindow = window.open(formLink, "_blank");

    // Check if the new window/tab was successfully opened
    if (newWindow) {
      // This part is to focus the new window, as Safari often blocks pop-ups
      newWindow.opener = null;
      newWindow.location.href = formLink;
      newWindow.focus();
    } else {
      // If newWindow is null, it means the pop-up was blocked, so you can provide a message to the user here
      alert("Pop-up blocked. Please allow pop-ups in your browser settings.");
    }
  };

  return (
    <section className="home-section-9">
      <div
        className="container sect-9-container"
        data-aos="fade-in"
        data-aos-duration="1000"
      >
        <h5 className="header">Download ExD Digital Profile</h5>
        <div className="content">
          <p>
          Keep Calm and Go Digital!!
          </p>
        </div>
        <form action="" className="sub-form">
          {/* <label htmlFor="exampleInputFirstName" className="form-label">
            Email
          </label> */}
          <br />
          <input
            type="email"
            className="email-field"
            id="email-field"
            placeholder="Enter your email address"
            required
            value={email} // Bind the input value to the state
            onChange={(e) => setEmail(e.target.value)} // Update the state as the user types
          />
          <br />
          <button className="btn">
            <Link className="btn-link" onClick={handleClick}>
              Sign Up!
            </Link>
          </button>
        </form>
      </div>
    </section>
  );
};

export default Section9;
