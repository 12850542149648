import React from "react";
const Section2 = ({ url }) => {
  return (
    <>
      <section className="team-section-2">
        <div
          className="container team-video-container"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          <div className="row video-row">
            <iframe
              src="https://www.youtube.com/embed/nyuYVZxaItQ?si=YrxrC0NJ46Mxzkiz"
              className="col-12 video"
              title="Team video"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
