import React, { useEffect, useState } from "react";

const Section2 = ({ url }) => {
  // for read more button
  const [displayDots, setDisplayDots] = useState(true);
  const [displayText, setDisplayText] = useState("none");
  const [buttonText, setButtonText] = useState("Read More");

  const handleButtonClick = () => {
    if (displayDots) {
      setDisplayDots(false);
      setButtonText("Read Less");
      setDisplayText("block");
    } else {
      setDisplayDots(true);
      setButtonText("Read More");
      setDisplayText("none");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 0) {
        setDisplayText("none");
      } else {
        setDisplayText("block");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial display state based on window width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <section className="home-section-2" id="home-section-2">
        <div
          className="container sect-2-container"
          style={{
            backgroundImage: "url(" + url + "images/homepage/bg-section-2.jpg)",
          }}
        >
          <div className="row">
            <div className="col-lg-5 col-md-5 col-sm-12 left-section child">
              <div className="left-txt">
                <p data-aos="fade-right" data-aos-duration="1000">
                  ExD Digital is your strategic partner in harnessing the power
                  of digital technologies to drive growth.
                  <br />
                  <br />
                  Our comprehensive suite of services leverages web
                  applications, mobile technology, and digital media to empower
                  organizations across diverse sectors, including FMCG, Oil &
                  Gas, Retail, Textile, Financial Services, Education, and
                  Non-profit. With ExD Digital by your side, achieving market
                  leadership becomes an achievable reality.
                </p>
                <span id="dots"></span>
                <span
                  id="more"
                  className="read-more-left-txt"
                  style={{ display: displayText }}
                >
                  Our relentless pursuit of excellence has led to groundbreaking
                  initiatives as we continue to revolutionize industry value
                  chains with pioneering solutions like Brain Box. With ExD
                  Digital, you're not just adapting to the digital landscape;
                  you're shaping the future of your industry.
                <br />
                <br />
                </span>
                <button onClick={handleButtonClick} className="btn home-sec-2-btn">
                  {buttonText}
                </button>
              </div>
              {/* <div className="left-btn">
                <button className="btn">
                  <Link
                    to="/"
                    className="btn-link"
                    data-aos="zoom-in"
                    data-aos-duration="1000"
                  >
                    Read More
                  </Link>
                </button>
              </div> */}
            </div>
            <div className="col-lg-7 col-md-7 col-sm-12 right-section child">
              <img
                loading="lazy"
                src={url + "images/homepage/hp-sect-2-img.png"}
                className="right-img"
                alt="Brain Storming"
                data-aos="fade-left"
                data-aos-duration="1000"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
