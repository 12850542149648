import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMapLocationDot,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const Section2 = ({ url }) => {
  return (
    <>
      <section className="contact-section-2" id="contact-section-2">
        <div className="container sect-1-container">
          <div className="contact-dtls">
            <div
              className="wrapper"
              data-aos="zoom-in"
              data-aos-duration="1200"
            >
              <div className="dtls-title">
                <h2>
                  Get in <span>Touch</span>!
                </h2>
              </div>
              <div className="contact-info">
                <div className="row contact-info-row">
                  <div className="address col-lg-4 col-md-4 col-12">
                    <FontAwesomeIcon icon={faMapLocationDot} className="i" />
                    <hr />
                    <div className="loc loc-1">
                      <span>Lahore:</span> ExD House, 89-I, Jail Road, Lahore -
                      Pakistan
                    </div>
                    <br />
                    <div className="loc loc-2">
                      <span>Karachi:</span> 59-C, 3rd Floor, Shehbaz Commercial
                      (Small) DHA Phase 6, Saba Avenue, Karachi - Pakistan
                    </div>
                    <br />
                    <div className="loc loc-3">
                      <span>Islamabad:</span> 1st Floor Offices #22-23-24-25
                      Pak-Land City Centre I-8 Markaz, Islamabad - Pakistan
                    </div>
                  </div>
                  <div className="phone-dtls col-lg-4 col-md-4 col-12">
                    <FontAwesomeIcon icon={faPhone} className="i" />
                    <hr />
                    <div className="phone phone-1">
                      <span>Lahore:</span> +92 423 540 8548-52{" "}
                      <span className="bars">||</span> +92 346 8596393
                    </div>
                    <br />
                    <div className="phone phone-2">
                      <span>Karachi:</span> +92 21 3524 7131{" "}
                      <span className="bars">||</span> +92 21 3524 7132
                    </div>
                    <br />
                    <div className="phone phone-3">
                      <span>UAN#</span> 0800 11393
                    </div>
                  </div>
                  <div className="email col-lg-4 col-md-4 col-12">
                    <FontAwesomeIcon icon={faEnvelope} className="i" />
                    <hr />
                    <div className="mail">social@exdnow.com</div>
                    <div className="map">
                      <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3400.4745655857014!2d74.33480447621628!3d31.538588246240682!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391906139f03cc69%3A0x8f0437331e0824cf!2sExD%20-%20Excellence%20Delivered%20ExD%20Pvt%20Ltd!5e0!3m2!1sen!2s!4v1690479586706!5m2!1sen!2s"
                        loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade"
                        title="ExD Digital"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section2;
