import React from "react";

import Section1 from "../includes/AboutUs/Section1";
import Section2 from "../includes/AboutUs/Section2";
import Section3 from "../includes/AboutUs/Section3";


// form from homepage

import Section9 from "../includes/Home/Section9";

import { Helmet } from "react-helmet";

const AboutUs = ({ devUrl }) => {
  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": "exddigital.com",
    },
    headline: "ExD Digital - About Us",
    image: "https://www.exddigital.com/images/about-us/main-banner.jpg",
    author: {
      "@type": "Organization",
      name: "ExD Digital - About Us",
      url: "https://www.exddigital.com/about-us",
    },
    publisher: {
      "@type": "Organization",
      name: "ExD Digital",
      logo: {
        "@type": "ImageObject",
        url: "https://www.exddigital.com/images/exd_digital.png",
      },
    },
    datePublished: "2023-08-02",
    dateModified: "2023-08-03",
  };

  return (
    <>
      <Helmet>
        <title>ExD Digital - About Us</title>
        <link rel="canonical" href="https://www.exddigital.com/about-us" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <main className="about-main">
        <article className="about-us">
          <Section1 url={devUrl} />
          <Section2 url={devUrl} />
          <Section3 url={devUrl} />

          <Section9 url={devUrl} />
        </article>
      </main>
    </>
  );
};
export default React.memo(AboutUs);
