import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";

const Section7 = ({ url }) => {
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    const handle_Scroll = () => {
      const triggerElement = document.querySelector(".home-section-7");
      const rect = triggerElement?.getBoundingClientRect();

      // Check if the trigger element is in the viewport
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setCounterOn(true);
      } else {
        setCounterOn(false);
      }
    };

    // Listen for scroll events
    window.addEventListener("scroll", handle_Scroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  return (
    <section className="home-section-7">
      <ScrollTrigger
        onEnter={() => setCounterOn(true)}
        onExit={() => setCounterOn(false)}
      ></ScrollTrigger>
      <div className="container sect-7-container">
        <div className="row">
          <div
            className="counter counter-1 col-lg-4 col-md-4 col-sm-12"
            data-aos="fade-down"
            aos-duration="1000"
          >
            <p className="counter-number">
              {counterOn && (
                <CountUp start={0} end={12} duration={2} delay={0} />
              )}
            </p>
            <p className="counter-title">Industries</p>
          </div>
          <div
            className="counter counter-2 col-lg-4 col-md-4 col-sm-12"
            data-aos="fade-down"
            aos-duration="1000"
          >
            <p className="counter-number">
              {counterOn && (
                <CountUp start={0} end={100} duration={2} delay={0} />
              )}
              +
            </p>
            <p className="counter-title">Clients</p>
          </div>
          <div
            className="counter counter-3 col-lg-4 col-md-4 col-sm-12"
            data-aos="fade-down"
            aos-duration="1000"
          >
            <p className="counter-number">
              0
              {counterOn && (
                <CountUp start={0} end={6} duration={2} delay={0} />
              )}
            </p>
            <p className="counter-title">Countries</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section7;
