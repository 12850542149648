import React, { useEffect, useState } from "react";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Section1 = ({ url }) => {
  // hollow text-animation
  useEffect(() => {
    const spotlightSize = 400;

    function handleMouseMove(event) {
      if (window.event) {
        event = window.event;
      }
      let titleRect = document
        .querySelector(".hollow-txt")
        .getBoundingClientRect();
      let mouseX = event.clientX;
      let spotlightX = mouseX - spotlightSize / 2 - titleRect.left;
      let mouseY = event.clientY;
      let spotlightY = mouseY - spotlightSize / 2 - titleRect.top;
      const element = document.querySelector(".hollow-txt");
      element.style.backgroundPosition = spotlightX + "px " + spotlightY + "px";
    }

    document.addEventListener("mousemove", handleMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  // color transitioning
  const [currentColor, setCurrentColor] = useState("black");

  useEffect(() => {
    const handle_Scroll = () => {
      const panels = document.querySelectorAll(".panel");
      const scroll = window.scrollY + window.innerHeight / 2;

      panels.forEach((panel) => {
        if (
          panel.offsetTop <= scroll &&
          panel.offsetTop + panel.offsetHeight > scroll
        ) {
          const newColor = panel.dataset.color;
          setCurrentColor(newColor);
        }
      });
    };

    window.addEventListener("scroll", handle_Scroll);
    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  // for counter
  const [counterOn, setCounterOn] = useState(false);

  useEffect(() => {
    const handle_Scroll = () => {
      const triggerElement = document.querySelector(".panel-3");
      const rect = triggerElement.getBoundingClientRect();

      // Check if the trigger element is in the viewport
      if (rect.top < window.innerHeight && rect.bottom >= 0) {
        setCounterOn(true);
      } else {
        setCounterOn(false);
      }
    };

    // Listen for scroll events
    window.addEventListener("scroll", handle_Scroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handle_Scroll);
    };
  }, []);

  // for read more button
  const [displayDots, setDisplayDots] = useState(true);
  const [displayText, setDisplayText] = useState("none");
  const [buttonText, setButtonText] = useState("Read more");

  const handleButtonClick = () => {
    if (displayDots) {
      setDisplayDots(false);
      setButtonText("Read less");
      setDisplayText("block");
    } else {
      setDisplayDots(true);
      setButtonText("Read more");
      setDisplayText("none");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setDisplayText("none");
      } else {
        setDisplayText("block");
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial display state based on window width

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className="about-section-1">
      <div className="transition-container sect-1-container">
        <div
          // className={`panel color-${currentColor} panel-1`}
          className="panel panel-1"
          data-color="black"
          style={{
            backgroundImage: "url(" + url + "images/about-us/main-banner.jpg)",
          }}
        >
          <div className="container panel-1-container">
            <div
              className="txt-1 col-12"
              data-aos="fade-up"
              data-aos-duration="1000"
            >
              <h1 className="hollow-txt">
                About <span className="yellow-txt">Us</span>
              </h1>
            </div>
            <div
              className="about-desc"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <h6 className="desc">
                From start to scaling, ExD Digital is there to help your brand
                at any stage, whether it be launch or growth.
              </h6>
            </div>
            <div className="scroller">
              <p className="scroller-text">Scroll Down</p>
              <a href="#panel-2" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faArrowDown} className="i" />
              </a>
            </div>
          </div>
        </div>
        <div
          className={`panel color-${currentColor} panel-2`}
          data-color="white"
          id="panel-2"
        >
          <div className="container panel-2-container">
            <div className="row panel-2-row">
              <div className="col-lg-7 col-md-7 col-sm-12 left-section child">
                <div className="txt">
                  <h5 data-aos="fade-left" data-aos-duration="1000">
                    <strong style={{ color: "#fcb61d" }}>About Us:</strong>
                    <br />
                    <br />
                    At ExD Digital, we are passionate about digital innovation
                    and dedicated to helping businesses thrive in the
                    ever-evolving online landscape. With a passion for crafting
                    digital experiences that leave an impact, we are a dynamic
                    team of creative minds, tech-savvy developers, and social
                    media strategists dedicated to helping businesses thrive in
                    the digital landscape.
                    <br />
                    <br />
                    <span id="dots"></span>
                    <span id="more" style={{ display: displayText }}>
                      <br />
                      <strong style={{ color: "#fcb61d" }}>
                        Our Approach:
                      </strong>
                      <br />
                      <br />
                      What sets us apart is our commitment to understanding your
                      unique business needs. We take the time to get to know
                      your brand, goals, and audience to tailor our services to
                      deliver exceptional results. Our mission is to drive
                      digital transformation for our clients by delivering
                      innovative solutions that exceed expectations. We are
                      committed to staying at the forefront of technology trends
                      and continuously improving our skills to provide the best
                      possible service.
                    </span>
                    {window.innerWidth < 768 && (
                      <button
                        onClick={handleButtonClick}
                        id="myBtn"
                        className="myBtn"
                      >
                        {buttonText}
                      </button>
                    )}
                  </h5>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12 right-section child">
                <img
                  loading="lazy"
                  src={url + "images/about-us/1.jpg"}
                  className="right-img"
                  alt="About ExD Digital"
                  data-aos="fade-left"
                  data-aos-duration="1000"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          className={`panel color-${currentColor} panel-3`}
          data-color="black"
        >
          <div
            className="container panel-3-container"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            <div className="row panel-3-row">
              <div className="col-lg-5 col-md-5 col-12 panel-3-txt">
                <h2 className="title">Our Fun Facts</h2>
                <p className="desc">
                  The young and dynamic team at ExD Digital thrives on
                  innovation and creativity. We strive to redefine the digital
                  landscape with cutting-edge solutions to craft tailored
                  strategies and deliver results that exceed expectations.{" "}
                </p>
              </div>
              <div className="col-lg-7 col-md-7 col-12 panel-3-counter">
                <ScrollTrigger
                  onEnter={() => setCounterOn(true)}
                  onExit={() => setCounterOn(false)}
                ></ScrollTrigger>
                <div className="row counter-row">
                  <div className="counter-1 col-lg-6 col-md-6 col-12 counters">
                    <p className="counter">
                      {counterOn && (
                        <CountUp
                          start={0}
                          end={2019}
                          duration={2}
                          delay={0}
                          useGrouping={false}
                        />
                      )}
                      {/* {counterOn && (
                        <CountUp start={0} end={19} duration={2} delay={0} />
                      )} */}
                    </p>
                    <p className="title">Year Founded</p>
                  </div>
                  <div className="counter-2 col-lg-6 col-md-6 col-12 counters">
                    <p className="counter">
                      {counterOn && (
                        <CountUp start={0} end={1} duration={8} delay={0} />
                      )}
                    </p>
                    <p className="title">Number of Patents</p>
                  </div>
                  <div className="counter-3 col-lg-6 col-md-6 col-12 counters">
                    <p className="counter">
                      {counterOn && (
                        <CountUp start={0} end={100} duration={2} delay={0} />
                      )}
                      +
                    </p>
                    <p className="title">Clients Served</p>
                  </div>
                  <div className="counter-4 col-lg-6 col-md-6 col-12 counters">
                    <p className="counter">
                      {counterOn && (
                        <CountUp start={0} end={300} duration={2} delay={0} />
                      )}
                      M
                    </p>
                    <p className="title">Global Reach</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Section1;
