import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

const Section1 = ({ url }) => {
  useEffect(() => {
    const spotlightSize = 400;

    function handleMouseMove(event) {
      if (window.event) {
        event = window.event;
      }
      let titleRect = document
        .querySelector(".hollow-txt")
        .getBoundingClientRect();
      let mouseX = event.clientX;
      let spotlightX = mouseX - spotlightSize / 2 - titleRect.left;
      let mouseY = event.clientY;
      let spotlightY = mouseY - spotlightSize / 2 - titleRect.top;
      const element = document.querySelector(".hollow-txt");
      element.style.backgroundPosition = spotlightX + "px " + spotlightY + "px";
    }

    document.addEventListener("mousemove", handleMouseMove, false);

    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <>
      <section
        className="team-section-1"
        style={{
          backgroundImage: "url(" + url + "images/our-team/main-banner.jpg)",
        }}
      >
        <div className="container sect-1-container">
          <div
            className="txt-1 col-12"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {/* <h1 className="white-txt">Innovate, <span className="yellow-txt">Ignite,</span> Create:</h1> */}
            <h1 className="white-txt">
              No <span className="yellow-txt">Myths</span> Here,
            </h1>
          </div>
          <div
            className="txt-3 col-12"
            data-aos="fade-up"
            data-aos-duration="1000"
          >
            {/* <h1 className="hollow-txt">Your Vision, Our Passion</h1> */}
            <p className="hollow-txt">Only Legends</p>
          </div>
          <div className="scroller">
            <p className="scroller-text">Scroll Down</p>
            <a href="#team-section-3" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faArrowDown} className="i" />
            </a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Section1;
